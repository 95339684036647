<template>
    <b-row class="match-height">
      <b-col
  lg="12"
  > <b-card
      title="Add New Transfer Asset"
    >
      <b-form
          @submit.prevent="submitNewTransfer"
  >
        <b-row>
          <b-col cols="12">
          <b-form-group>
            <label for="no_invoice">Request To Branch *</label>
            <v-select-new
                  v-model="chosenBranch"
                  :options="branches"
                  required="required"
                />
          </b-form-group>
          <b-form-group>
            <label for="no_invoice">Notes </label>
              <b-form-input
                id="notes"
                v-model="transferNotes"
                placeholder="Notes"
              />
          </b-form-group>
          <b-form-group
                label="Document *"
                label-for="file"
                label-cols-md="12"
              >
            <b-form-file
              id="file"
              ref="uploadFile"
              placeholder="Choose one file or drop it here..."
  drop-placeholder="Drop a file here..."
  type="file"
  @change="handleFilesUpload"
            />
              </b-form-group>
              <b-form-group
                label="Items *"
                label-for="chosenItem"
              >

              <Select2 v-model="chosenItem" :settings="settings" @select="putItem($event)" />
              </b-form-group>
              <b-row>
              <b-col
  cols="6"
  class="text-center font-weight-bold"
  >
                Item name
              </b-col>
              <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
                Item QTY
              </b-col>
              <b-col
  cols="3"
  class="text-center font-weight-bold"
  >
                Actions
              </b-col>
              </b-row>
             <p />
             <div>
            <b-row
  v-for="(chosen) in chosens"
  :key="chosen.value"
  >
            <br>
              <b-col cols="6 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
  {{ chosen.label }}
  </b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="3 text-center">
              <b-form-input
                v-model="chosen.qty"
                type="number"
                placeholder="Qty"
                min="1"
                required="required"
              />
              </b-col>
              <b-col cols="3 text-center">
            <b-button
              size="sm"
              type="button"
              variant="danger"
              class="mr-1"
              @click="removeRow(chosen.value)"
            >
                  <feather-icon
                    icon="TrashIcon"
                    size="18"
                  />
            </b-button>
              </b-col>
              <b-col cols="12">
                <br>
              </b-col>
              </b-row>
            </div>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>

      </b-form>

    </b-card>
      </b-col>
    </b-row>
  </template>

  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker, BFormFile,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import Select2 from 'v-select2-component'
  import { getUserData } from '@/auth/utils'
  import { ref } from '@vue/composition-api'

  const userData = getUserData()
  const VersionCheck = ''
  const file = ''
  const items = []
  const chosenType = 'Asset'
  const types = ["Asset", "Non Asset"]
  const chosenPR = ref()
  const chosenItem = ref(3)
  const chosens = []
  const shipmentTo = ''
  const itemId = ''
  const purchaseOrderProject = ''
  const purchaseOrderCostCenter = ''
  const purchaseOrderLink = ''
  const purchaseOrderQtyRequested = 0
  const purchaseOrderStatus = ''
  const purchaseOrderNotes = ''
  const deliverySchedule = ''
  const modalReferenceShow = false
  const itemReference = ''
  const itemLink = ''
  const itemPrice = ''
  const itemDescription = ''
  const itemSupplier = ''
  const contacts = []
  const branches = []
  const references = []
  const currencies = []
  const chosenBranch = ''
  const chosenContact = ''
  const termOfPayment = ''
  const billingAddress = ''
  const deliveryAddress = ''
  const itemCurrency = ''
  const paymentTypes = ['Credit', 'Cash', 'COD']
  const transferNotes = ''
  const modalReferenceHistoryShow = false

  export default {
    components: {
      Select2,
      BFormFile,
      BFormDatepicker,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      const userToken = this.$cookies.get("userToken")
      return {
        settings: {
          ajax: {
            url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ASSET_ITEMS}`,
            beforeSend: xhr => {
              xhr.setRequestHeader('Authorization', `Bearer ${userToken}`)
            },
            data: params => {
              const query = {
                search: params.term,
                offset: 0,
                limit: 10,
                branch_id: this.chosenBranch.value,
                withCount: false,
                type: this.chosenType,
              }

              // Query parameters will be ?search=[term]&type=public
              return query
            },
            processResults: response => {
              let resItems = response.data
              resItems = resItems.map(item => {
                item.text = `${item.asset_number} - ${item.item.item_name}`
                item.value = item.asset_id
                item.id = item.asset_id
                return item
              })
              // Transforms the top-level key of the response object from 'items' to 'results'
              return {
                results: resItems,
              }
            },
          },
        },
        chosenItem,
        purchaseOrderLink,
        itemSupplier,
        paymentTypes,
        transferNotes,
        itemCurrency,
        currencies,
        deliveryAddress,
        chosenBranch,
        branches,
        termOfPayment,
        billingAddress,
        modalReferenceHistoryShow,
        chosenContact,
        contacts,
        itemId,
        deliverySchedule,
        file,
        modalReferenceShow,
        itemReference,
        itemLink,
        itemPrice,
        itemDescription,
        references,
      VersionCheck,
      items,
      types,
      chosenType,
      chosenPR,
      chosens,
      shipmentTo,
      purchaseOrderProject,
      purchaseOrderCostCenter,
      purchaseOrderQtyRequested,
      purchaseOrderStatus,
      purchaseOrderNotes,
      }
    },
    mounted() {
      this.chosens = []
        this.getBranches()
        this.getCurrencies()
    },
    methods: {
      getCurrencies() {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CURRENCIES}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                const itemsJoin = []
                response.data.data.map(elem => {
                  itemsJoin.push({ label: `${elem.currency_full_name} (${elem.currency_symbol})`, value: elem.currency_id })
                })
                this.currencies = itemsJoin
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Currencies Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Branches Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
      getBranches() {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}?all=true`, { headers })
        .then(response => {
          if (response.data.success === true) {
                const itemsJoin = []
                response.data.data.map(elem => {
                  console.log(elem)
                  if (userData.user_branch_id !== elem.branch_id) {
                  itemsJoin.push({ label: `${elem.branch_name} (${elem.branch_code})`, value: elem.branch_id })
                  }
                })
                console.log(itemsJoin)
                this.branches = itemsJoin
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Branches Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Branches Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    submitNewTransfer() {
      const userToken = this.$cookies.get('userToken')
      const header = {
          'Content-Type': 'multipart/form-dataitem',
          Authorization: `Bearer ${userToken}`,
        }
        if (this.chosens.length > 0) {
          if (this.file[0] !== null) {
        const formData = new FormData()
        formData.append('file', this.file[0], this.file[0].name)
        formData.append('transfer_notes', this.transferNotes)
        formData.append('branch',this.chosenBranch.value)
        formData.append('type', 'Asset')
        formData.append('transfer_items', JSON.stringify(this.chosens))
        formData.append('time', moment())
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_CREATE_TRANSFER}`
        console.log(url)
      axios
        .post(url, formData, { headers: header })
        .then(response => {
          if (response.data.success === true) {
            this.chosens = []
            this.transferNotes = ""
            this.chosenBranch = null
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Transfer Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-transfers-list' })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Transfer Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please upload the document",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
      }
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "You have to choose at least one item",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        }
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem({ id, text }) {
        if (this.chosens.length > 0) {
          const item = {
            label: text,
            value: id,
            qty: 1,
            id: this.chosens.length + 1,
            shelf: "",
          }
          this.chosens.push(item)
          // }
        } else {
          const item = {
            label: text,
            value: id,
            qty: 1,
            id: this.chosens.length,
            shelf: "",
          }

          this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
      addReference(val) {
        this.modalReferenceShow = true
        this.itemId = val
      },
      checkType() {
        this.chosens = []
      },
      showReference(val) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        this.itemId = val
        this.modalReferenceHistoryShow = true
      axios
        .get(`${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                this.references = response.data.data
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get References Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get References Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
      choose(referenceId, itemIdRef, price) {
        const index = this.chosens.findIndex(x => x.value === itemIdRef)
        this.chosens[index].reference_id = referenceId
        this.chosens[index].price = price
        this.modalReferenceHistoryShow = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Price Chosen',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      saveNewReference() {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        this.modalReferenceShow = false
        const body = {
          item_reference_item_id: this.itemId,
          item_reference_link: this.itemLink,
          item_reference_price: this.itemPrice,
          item_reference_description: this.itemDescription,
          item_reference_currency_id: this.itemCurrency.value,
          item_reference_supplier: this.itemSupplier,
          time: moment(),
        }
      axios
        .post(`${process.env.VUE_APP_API_CREATE_ITEM_LOOKUP}`, body, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.itemId = ""
            this.itemLink = ""
            this.itemPrice = ""
            this.itemDescription = ""
            this.itemCurrency = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'New Reference Added',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Adding New Reference Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Adding New Reference Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        this.itemReference = ""
        this.itemLink = ""
        this.itemPrice = ""
        this.itemDescription = ""
        this.modalReferenceShow = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data Saved',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      },
    },
  }
  </script>
